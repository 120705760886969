.rc-tabs {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}
.rc-tabs-bar,
.rc-tabs-nav-container {
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    outline: none;
    zoom: 1;
    transition: padding .45s;
}
.rc-tabs-ink-bar {
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    margin-top: -3px;
    background-color: #ffffff;
    transform-origin: 0 0;
}
.rc-tabs-ink-bar-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-tabs-tab-prev,
.rc-tabs-tab-next {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    line-height: 36px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
}
.rc-tabs-tab-prev-icon,
.rc-tabs-tab-next-icon {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: inherit;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    font-smoothing: antialiased;
    text-stroke-width: 0;
    font-family: sans-serif;
}
.rc-tabs-tab-prev-icon:before,
.rc-tabs-tab-next-icon:before {
    display: block;
}
.rc-tabs-tab-btn-disabled {
    cursor: default;
    color: #ccc;
}
.rc-tabs-nav-wrap {
    overflow: hidden;
}
.rc-tabs-nav {
    box-sizing: border-box;
    padding-left: 0;
    position: relative;
    margin: 0;
    float: left;
    list-style: none;
    display: inline-block;
    transform-origin: 0 0;
}
.rc-tabs-nav-animated {
    transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-tabs-nav:before,
.rc-tabs-nav:after {
    display: table;
    content: " ";
}
.rc-tabs-nav:after {
    clear: both;
}
.rc-tabs-tab {
    box-sizing: border-box;
    position: relative;
    display: block;
    transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: 8px 20px;
    font-weight: 500;
    cursor: pointer;
}
.rc-tabs-tab:hover {
    color: #23c0fa;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active:hover {
    color: #108ee9;
    cursor: default;
    transform: translateZ(0);
}
.rc-tabs-tab-disabled {
    cursor: default;
    color: #ccc;
}
.rc-tabs-tab-disabled:hover {
    color: #ccc;
}
.rc-tabs-content {
    zoom: 1;
}
.rc-tabs-content .rc-tabs-tabpane {
    overflow: auto;
}
.rc-tabs-content-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), margin-left 0.3s cubic-bezier(0.35, 0, 0.25, 1), margin-top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    display: -ms-flexbox;
    display: flex;
    will-change: transform;
}
.rc-tabs-content-animated .rc-tabs-tabpane {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.no-flexbox .rc-tabs-content {
    transform: none !important;
    overflow: auto;
}
.no-csstransitions .rc-tabs-tabpane-inactive,
.no-flexbox .rc-tabs-tabpane-inactive,
.rc-tabs-content-no-animated .rc-tabs-tabpane-inactive {
    display: none;
}
.rc-tabs-left {
    border-right: 2px solid #f3f3f3;
}
.rc-tabs-left .rc-tabs-bar {
    float: left;
    height: 100%;
    margin-right: 10px;
    border-right: 1px solid #f3f3f3;
}
.rc-tabs-left .rc-tabs-nav-container {
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
}
.rc-tabs-left .rc-tabs-nav-wrap {
    height: 100%;
}
.rc-tabs-left .rc-tabs-content-animated {
    -ms-flex-direction: column;
    flex-direction: column;
}
.rc-tabs-left .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-scroll {
    height: 99999px;
}
.rc-tabs-left .rc-tabs-nav-swipe {
    position: relative;
    top: 0;
}
.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-left .rc-tabs-tab-prev,
.rc-tabs-left .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    line-height: 32px;
    width: 0;
    display: block;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show,
.rc-tabs-left .rc-tabs-tab-arrow-show,
.rc-tabs-bottom .rc-tabs-tab-arrow-show,
.rc-tabs-right .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
}
.rc-tabs-left .rc-tabs-tab-next {
    bottom: 0;
}
.rc-tabs-left .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rc-tabs-left .rc-tabs-tab-next-icon:before {
    content: ">";
}
.rc-tabs-left .rc-tabs-tab-prev {
    top: 2px;
}
.rc-tabs-left .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rc-tabs-left .rc-tabs-tab-prev-icon:before {
    content: ">";
}
.rc-tabs-left .rc-tabs-ink-bar {
    width: 2px;
    right: 0;
}
.rc-tabs-left .rc-tabs-tab {
    padding: 16px 24px;
}
.rc-tabs-right {
    border-left: 2px solid #f3f3f3;
}
.rc-tabs-right .rc-tabs-bar {
    float: right;
    height: 100%;
    margin-left: 10px;
    border-left: 1px solid #f3f3f3;
}
.rc-tabs-right .rc-tabs-nav-container {
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
}
.rc-tabs-right .rc-tabs-nav-wrap {
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-scroll {
    height: 99999px;
}
.rc-tabs-right .rc-tabs-nav-swipe {
    position: relative;
}
.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-right .rc-tabs-tab-prev,
.rc-tabs-right .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    width: 0;
    display: block;
    text-align: center;
    line-height: 32px;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
}
.rc-tabs-right .rc-tabs-tab-next {
    bottom: 0;
}
.rc-tabs-right .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.rc-tabs-right .rc-tabs-tab-next-icon:before {
    content: ">";
}
.rc-tabs-right .rc-tabs-tab-prev {
    top: 2px;
}
.rc-tabs-right .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rc-tabs-right .rc-tabs-tab-prev-icon:before {
    content: ">";
}
.rc-tabs-right .rc-tabs-content-animated {
    -ms-flex-direction: column;
    flex-direction: column;
}
.rc-tabs-right .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
}
.rc-tabs-right .rc-tabs-ink-bar {
    width: 2px;
    left: 0;
}
.rc-tabs-right .rc-tabs-tab {
    padding: 16px 24px;
}
.rc-tabs-bottom {
    border-top: 2px solid #f3f3f3;
}
.rc-tabs-bottom .rc-tabs-content {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-bar {
    border-top: 1px solid #f3f3f3;
}
.rc-tabs-bottom .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
}
.rc-tabs-bottom .rc-tabs-nav-scroll {
    width: 99999px;
}
.rc-tabs-bottom .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
}
.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-bottom .rc-tabs-nav-wrap {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-content-animated {
    -ms-flex-direction: row;
    flex-direction: row;
}
.rc-tabs-bottom .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
}
.rc-tabs-bottom .rc-tabs-tab-next {
    right: 2px;
}
.rc-tabs-bottom .rc-tabs-tab-next-icon:before {
    content: ">";
}
.rc-tabs-bottom .rc-tabs-tab-prev {
    left: 0;
}
.rc-tabs-bottom .rc-tabs-tab-prev-icon:before {
    content: "<";
}
.rc-tabs-bottom .rc-tabs-tab-prev,
.rc-tabs-bottom .rc-tabs-tab-next {
    margin-right: -2px;
    width: 32px;
    height: 100%;
    top: 0;
    text-align: center;
}
.rc-tabs-bottom .rc-tabs-ink-bar {
    height: 2px;
    top: 3px;
    left: 0;
}
.rc-tabs-bottom .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
}
.rc-tabs-bottom .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
}
.rc-tabs-top {
   
}
.rc-tabs-top .rc-tabs-content {
    width: 100%;
}
.rc-tabs-top .rc-tabs-bar {

}
.rc-tabs-top .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
}
.rc-tabs-top .rc-tabs-nav-scroll {
    width: 99999px;
}
.rc-tabs-top .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
}
.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    background-color: #1C6EBA;
    padding: 4px;
    border-radius: 100px;
}
.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    z-index: 2;
    -ms-flex-pack: center;
    justify-content: center;
}
.rc-tabs-top .rc-tabs-nav-wrap {
    width: 100%;
}
.rc-tabs-top .rc-tabs-content-animated {
    -ms-flex-direction: row;
    flex-direction: row;
}
.rc-tabs-top .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
}
.rc-tabs-top .rc-tabs-tab-next {
    right: 2px;
}
.rc-tabs-top .rc-tabs-tab-next-icon:before {
    content: ">";
}
.rc-tabs-top .rc-tabs-tab-prev {
    left: 0;
}
.rc-tabs-top .rc-tabs-tab-prev-icon:before {
    content: "<";
}
.rc-tabs-top .rc-tabs-tab-prev,
.rc-tabs-top .rc-tabs-tab-next {
    margin-right: -2px;
    width: 0;
    height: 0;
    top: 0;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
}
.rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 32px;
    height: 100%;
}
.rc-tabs-top .rc-tabs-ink-bar {
    height: 38px;
    bottom: 5.4px;
    left: 0.2px;
    border-radius: 100px;
    z-index: 1;
}
.rc-tabs-top .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
}
.rc-tabs-top .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
}

.tab-padding{
    padding: 100px;
}