.react-calendar__tile {
    padding: 0 !important;
}

.react-calendar__month-view__weekdays {
    abbr {
        display: block !important;
        border: none !important;
        text-decoration: none !important;
    }
}

.react-calendar__tile--now{
    background: none !important;
}