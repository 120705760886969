@font-face {
    font-family: 'Archivo-Bold';
    src: local('Archivo-Bold'), url(../assets/fonts/Archivo-Bold.ttf);
}

@font-face {
    font-family: 'Archivo-Medium';
    src: local('Archivo-Medium'), url(../assets/fonts/Archivo-Medium.ttf);
}

@font-face {
    font-family: 'Archivo-SemiBold';
    src: local('Archivo-SemiBold'), url(../assets/fonts/Archivo-SemiBold.ttf);
}

@font-face {
    font-family: 'Archivo-Regular';
    src: local('Archivo-Regular'), url(../assets/fonts/Archivo-Regular.ttf);
}

@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'), url(../assets/fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), url(../assets/fonts/Lato-Light.ttf);
}

@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato-Regular'), url(../assets/fonts/Lato-Regular.ttf);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: #000000 !important;
}

abbr {
    display: none;
}
.react-calendar__tile:disabled{
    background-color: #fff !important;
}
